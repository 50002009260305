<template>
  <div class="pad-home">
    <HeaderBar
      title="车型列表"
      :showLeft="true"
      :refresh="true"
      @refreshaa="getdata"
    ></HeaderBar>
    <div class="headbox"></div>
    <span v-if="carList.length>0">
      <div
        v-for="(item, index) of carList"
        :key="index"
        class="carItem"
        @click="selectCar(item)"
      >
        <div class="carImg">
          <img
            :src="imgUrl"
            alt
            class
          />
        </div>
        <div class="carInfo">
          <div class="carName">
            <div class="nameTxt">{{item.PartnerName}}</div>
            <div
              class="nameTip"
              v-if="item.IsChildSeat=='1'"
            >有婴儿椅</div>
          </div>
          <div class="carTypeName">{{item.VehicleName}}</div>
          <div class="carTips">
            <div>
              <i class="carSitIcon"></i>
              <span class="carSit">{{item.MannedNumber}}人</span>
            </div>
            <div class="carRate">
              <span class="rate">5.0</span>
              <div class="star-list">
                <i
                  class="star-icon"
                  v-for="(s,i) in star"
                  :key="i"
                ></i>
              </div>
            </div>
            <div class="carCurrentInfo">
              <!-- <span class="time">{{Math.ceil(c.Distance/1000)}}min</span> -->
              <span class="distance">{{`${(item.Distance/1000).toFixed(1)}km`}}</span>
            </div>
          </div>
        </div>

      </div>
    </span>

    <div
      v-if="showtxt"
      class="errtxt"
    >
      暂无更多司机，顶部按钮可刷新
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Rate, List, PullRefresh, Toast } from "vant";
import { mapState, mapMutations /*mapGetters */ } from "vuex";
import { getStore } from "../../lib/storage";
import { getCarList } from "@/service/";

export default {
  name: "carList",
  components: {
    HeaderBar,
    [Rate.name]: Rate,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      carList: [],
      showtxt: false,
      value: 4,
      star: 5,
      page: 1,
      loading: false,
      finished: false,
      lastPage: false,
      freshHeight: "",
    };
  },
  computed: {
    ...mapState({
      // carList: ({ globalVal }) => globalVal.carList,
      // tripCarList: ({ globalVal }) => globalVal.tripCarList,
      peopleNum: ({ globalVal }) => globalVal.peopleNum,
      selectedCar: ({ globalVal }) => globalVal.selectedCar,
      startGeo: ({ globalVal }) =>
        globalVal.startGeo || JSON.parse(getStore("startGeo")),
    }),
    // ...mapGetters(['tripCarList']),
    imgUrl() {
      return require(`../../assets/car@2x.png`);
    },
    type() {
      return this.$route.params.dccode;
    },
  },
  methods: {
    ...mapMutations(["SELECT_CAR", "GET_CARLIST_AROUND"]),
    // ...mapActions(['getCarListAround']),
    selectCar(item) {
      this.SELECT_CAR(item);
      this.$router.go(-1);
    },
    onLoad() {},
    getdata() {
      console.log("getdata");
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.showtxt = false;
      let latlng = JSON.stringify(this.startGeo);
      // let type = this.typeList[0].typenumber
      getCarList(latlng, this.type).then((res) => {
        this.$toast("刷新成功");
        if (res.code == 1) {
          if (res.data.length > 0) {
            this.carList = res.data || [];
            if (
              JSON.stringify(res.data).indexOf(
                JSON.stringify(this.selectedCar)
              ) < 0
            ) {
              this.SELECT_CAR("");
            }
            // console.log(JSON.stringify(res.data).indexOf(JSON.stringify(this.selectedCar)))
            this.GET_CARLIST_AROUND(res.data || []);
          } else {
            this.showtxt = true;
          }
        }
      });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.list-container {
  position: absolute;
  top: 94px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.headbox {
  width: 100%;
  height: 100px;
}

.carItem {
  .size(100%, 160px);
  display: flex;
  flex: 1;
  padding: 0 30px;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid #eee;

  .carImg {
    display: flex;
    align-items: center;
    .size(120px, 120px);
    margin-right: 20px;
    background: #f8f8f8;
    .text-center;
    box-sizing: border-box;
    border-radius: 8px;

    img {
      .size(92px, 44px);
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .carInfo {
    // margin-right: auto;
    width: e("calc(100% - 140px)");
    .carName {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      .nameTxt {
        // width: 122px;
        height: 42px;
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
      }
      .nameTip {
        width: 120px;
        height: 40px;
        background: rgba(12, 170, 50, 0.1);
        border-radius: 20px;
        font-size: 24px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(4, 186, 47, 1);
        line-height: 40px;
        .text-center;
      }
    }
    .carTypeName {
      display: flex;
      flex: 1;
      align-items: center;
      height: 34px;
      margin-bottom: 4px;
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 34px;
    }
    .carStar {
      display: flex;
      flex: 1;
      align-items: center;
      height: 34px;
      margin-bottom: 12px;
      font-size: 24px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(245, 166, 35, 1);
      line-height: 34px;
    }

    .carTips {
      display: flex;
      flex: 1;
      align-items: center;
      // justify-content: space-between;
      font-family: "PingFangSC-Medium";
      font-size: 24px;
      color: #666666;

      .carSitIcon {
        display: inline-block;
        .size(22px, 22px);
        margin-right: 10px;
        background: url("../../assets/people@2x.png") center center no-repeat;
        background-size: 22px 22px;
      }
      .carCurrentInfo {
        margin-left: auto;
      }
      .time {
        display: inline-block;
        margin-right: 20px;
      }
      .distance {
        display: inline-block;
      }

      .carRate {
        display: flex;
        flex: 1;
        align-items: center;

        margin-left: 20px;
        .rate {
          display: inline-block;
          margin-right: 4px;
          color: rgba(245, 166, 35, 1);
        }
        .star-list {
          display: flex;
          flex: 1;
          align-items: center;
        }
        .star-icon {
          // display: inline-block;
          .size(26px, 26px);
          margin-right: 4px;
          background: url("../../assets/star-big@2x.png") center center
            no-repeat;
          background-size: 26px 26px;
        }
      }
    }
  }
}
.carfirst {
  height: 94px;
  margin: 0;
  padding: 0;
}
.errtxt {
  text-align: center;
  margin: 20px 0;
  font-size: 30px;
  color: #ccc;
}
</style>